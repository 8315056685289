import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Params, Router } from '@angular/router';
import { UserRoles } from '../../../auth/entities/user';
import { CurafidaAuthService } from '../../../auth/services';
import { ConfigService } from '../../../config/services';
import { StyleService } from '../../services/style/style.service';
import { RoutingSegment } from '../../entities/routing-segment';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'lib-curafida-page-title',
    templateUrl: './page-title.component.html',
    styleUrls: ['./page-title.component.scss'],
})
export class PageTitleComponent implements OnInit {
    roles: UserRoles[] | string[] = [];
    UserRoles = UserRoles;
    @Input()
    title: string;

    @Input()
    subtitle: string;

    @Input()
    prefix: string;

    @Input()
    username: string;
    @Input()
    queryParams: Params;
    @Input()
    buttonIcon: string;
    @Input()
    hasChat: boolean;
    @Input()
    unreadChat: number;
    @Output()
    emitActionIconButton = new EventEmitter();
    @Output()
    openChat = new EventEmitter();
    isMobile = false;
    imagePath: string;
    @Input()
    imageName: string;

    @Input()
    hasReport: boolean;

    @Output()
    reportButtonClick = new EventEmitter<void>();

    constructor(
        public router: Router,
        protected authService: CurafidaAuthService,
        public configService: ConfigService,
        public styleService: StyleService,
    ) {
        this.isMobile = this.styleService.isMobile$;
    }

    async ngOnInit(): Promise<void> {
        this.roles = await this.getRoles();
        if (this.imageName) this.imagePath = 'assets/imgs/' + this.imageName;
    }

    async getRoles(): Promise<UserRoles[] | string[]> {
        return await this.authService.getRoles();
    }

    async openPatientPage(): Promise<void> {
        if (this.username) {
            await this.router.navigate(
                [RoutingSegment.MEMBER, RoutingSegment.PATIENT_MANAGEMENT, RoutingSegment.DETAIL, this.username],
                { queryParams: this.queryParams },
            );
        }
    }

    openChatModal(): void {
        this.openChat.emit();
        this.unreadChat = 0;
    }
}
