// noinspection ES6UnusedImports

import { Injectable } from '@angular/core';
import { HttpMethod, HypermediaResource } from '../../../hateoas/hateoas.model';
import {
    SortTaskPropertyMap,
    TaskActionLinkName,
    TaskPreviewResource,
    TaskResource,
} from '../../components/task/task.resource';
import { ExerciseSessionAppointment } from '../../entities/appointement';
import { ApiService } from '../../../api';
import { HttpClient } from '@angular/common/http';
import { AuthorizationPipe, NoAuthorizationPipe } from '../../../hateoas/authorization.pipe';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin, Observable, of, Subject } from 'rxjs';
import { SegmentType } from '../../../common/entities/segment.type';
import { ExerciseSessionDto, ExerciseSessionState, ExerciseSessionUserState } from '../../entities/exerciseSession';
import { PaginatedResponse, SortOrder } from '../../../common/entities/paginated-response';
import { mergeMap } from 'rxjs/operators';
import { TaskResponsibleDto, TasksSearchParameter } from '../../components/task/task.dto';
import { XapiLesson } from '../../entities/xapi/xapi-finished-lessons.dto';
import { Logger } from '../../../logging/logging.service';

@Injectable({
    providedIn: 'root',
})
export class TaskService {
    readonly patientTasks$ = new Subject<TaskResource[]>();
    readonly statisticOfPatientTasks$ = new Subject<TaskResource[]>();
    private readonly finishedTaskStates = [ExerciseSessionState.FINISHED];
    private readonly finishedTaskUserStates = [ExerciseSessionUserState.FINISHED];
    private readonly cancelTaskStates = [ExerciseSessionState.CANCELLED, ExerciseSessionState.PATIENT_CANCELLED];
    private readonly cancelTaskUserStates = [
        ExerciseSessionUserState.CANCELLED,
        ExerciseSessionUserState.PATIENT_CANCELLED,
    ];
    private readonly activeTaskStates = [ExerciseSessionState.NOT_PLANNED, ExerciseSessionState.ACTIVE];
    private readonly plannedTaskStates = [ExerciseSessionState.PLANNED];
    private readonly plannedTaskUserStates = [ExerciseSessionUserState.PLANNED];
    private readonly expiredTaskStates = [ExerciseSessionUserState.NO_SHOW];
    private readonly log: Logger;

    constructor(
        protected http: HttpClient,
        private authorizationPipe: AuthorizationPipe,
        private readonly noAuthorizationPipe: NoAuthorizationPipe,
        private readonly translateService: TranslateService,
    ) {}

    initPatientTasks(
        args: {
            username: string;
            startOfInterval?: string;
            endOfInterval?: string;
            offset?: number;
            limit?: number;
            taskUserStates?: ExerciseSessionUserState[];
        },
        isStatistics = false,
    ): void {
        const url = new URL(`${ApiService.url}tasks`);
        if (args.offset) url.searchParams.set('offset', args.offset.toString());
        if (args.limit) url.searchParams.set('limit', args.limit.toString());
        if (args.taskUserStates) {
            for (const state of args.taskUserStates) {
                url.searchParams.append('exerciseSessionUserStates', state);
            }
        }
        if (args.startOfInterval) url.searchParams.set('startTime', args.startOfInterval);
        if (args.endOfInterval) url.searchParams.set('endTime', args.endOfInterval);
        this.http
            .get<PaginatedResponse<TaskPreviewResource[]>>(url.toString())
            .pipe(
                mergeMap((it) => {
                    /* If the items of the PaginatedResponse is an empty array, make an early return */
                    if (!it.items || it.items.length < 1) {
                        return of([] as TaskResource[]);
                    }
                    return forkJoin([
                        ...it.items.map((task: TaskPreviewResource) => {
                            const url = new URL(`${ApiService.url}tasks/${task.id}`);
                            return this.http.get<TaskResource>(url.toString());
                        }),
                    ]);
                }),
            )
            .subscribe((it) => {
                if (isStatistics) {
                    this.statisticOfPatientTasks$.next(it);
                } else {
                    this.patientTasks$.next(it);
                }
            });
    }

    async fetchTasks(
        username?: string,
        args?: TasksSearchParameter,
    ): Promise<PaginatedResponse<TaskPreviewResource[]>> {
        const url = new URL(`${ApiService.url}tasks`);
        if (username) url.searchParams.set('concernedUsername', username);

        if (args.offset) {
            url.searchParams.set('offset', args.offset.toString());
        } else {
            url.searchParams.set('offset', '0');
        }
        if (args.limit) {
            url.searchParams.set('limit', args.limit.toString());
        } else {
            url.searchParams.set('limit', '10');
        }
        if (args.sortBy && args.sortBy.length > 0) {
            args.sortBy = args.sortBy.filter((x) => x !== undefined && x !== null);
            for (const sortByEntry of args.sortBy) {
                url.searchParams.append('sortBy', sortByEntry);
            }
        }
        if (args.sortOrder) url.searchParams.set('sortOrder', args.sortOrder);

        if (args.exerciseSessionStates) {
            for (const state of args.exerciseSessionStates) {
                {
                    url.searchParams.append('exerciseSessionStates', state);
                }
            }
        }
        if (args.exerciseSessionUserStates) {
            for (const state of args.exerciseSessionUserStates) {
                {
                    url.searchParams.append('exerciseSessionUserStates', state);
                }
            }
        }
        if (args.responsible) url.searchParams.set('responsible', args.responsible);
        if (args.textSearchTerm) url.searchParams.set('textSearchTerm', args.textSearchTerm);

        args?.tagUuids?.forEach((tagUuid) => url.searchParams.append('tagUuids', tagUuid));
        args?.iconFilter?.forEach((it) => url.searchParams.append('iconFilter', JSON.stringify(it)));

        if (args.includeStateChanges) {
            url.searchParams.set('includeStateChanges', args.includeStateChanges.toString());
        }
        if (args.onlyWithCalendarEvent) {
            url.searchParams.set('onlyWithCalendarEvent', args.onlyWithCalendarEvent.toString());
        }
        return await this.http
            .get<PaginatedResponse<TaskPreviewResource[]>>(url.toString(), ApiService.options)
            .toPromise();
    }

    async fetchTask(taskId: number): Promise<TaskResource> {
        const url = new URL(`${ApiService.url}tasks/${taskId}`);
        return this.http.get<TaskResource>(url.toString(), ApiService.options).toPromise();
    }

    async createTask(username: string, exerciseSessionDto: ExerciseSessionDto): Promise<TaskResource> {
        const url = `${ApiService.url}tasks`;
        return this.http.post<TaskResource>(url, exerciseSessionDto, ApiService.options).toPromise();
    }

    postTaskAction(
        taskResource: HypermediaResource,
        taskAction: TaskActionLinkName,
    ): Promise<ExerciseSessionAppointment> {
        if (this.noAuthorizationPipe.transform(taskResource, taskAction, 'write')) {
            throw new Error(this.translateService.instant('FORBIDDEN'));
        }
        const url = new URL(taskResource._links[taskAction].href, ApiService.url);
        return this.http.post<ExerciseSessionAppointment>(url.toString(), '', ApiService.options).toPromise();
    }

    deleteTaskAppointment(task: TaskResource): Observable<void> {
        if (!task.appointment || this.noAuthorizationPipe.transform(task, 'appointment', HttpMethod.DELETE)) {
            return;
        }
        const url = new URL(task._links.appointment.href, ApiService.url);
        return this.http.delete<void>(url.toString(), ApiService.options);
    }

    deleteTaskCalendarEvent(task: TaskResource): Observable<void> {
        if (!task.calendarEvent || this.noAuthorizationPipe.transform(task, 'calendarEvent', HttpMethod.DELETE)) {
            return;
        }
        const url = new URL(task._links.calendarEvent.href, ApiService.url);
        return this.http.delete<void>(url.toString(), ApiService.options);
    }

    setStateConfiguration(segment: SegmentType) {
        let taskStates: ExerciseSessionState[] = [];
        let taskUserStates: ExerciseSessionUserState[] = [];
        let sortOrder: SortOrder = SortOrder.DESC;
        let sortBy: SortTaskPropertyMap[] = [];
        let tableColumnId: string = undefined;
        switch (segment) {
            case SegmentType.PLANNED:
                taskStates = this.plannedTaskStates;
                taskUserStates = this.plannedTaskUserStates;
                sortBy = [
                    SortTaskPropertyMap.DELAYED_TIME,
                    SortTaskPropertyMap.CALENDAR_EVENT_START_TIME,
                    SortTaskPropertyMap.CREATED_AT,
                ];
                sortOrder = SortOrder.ASC;
                tableColumnId = 'dueDate';
                break;

            /* Summary of ACTIVE and PLANNED segment but only the tasks with a calendar event */
            case SegmentType.CALENDAR_EVENT:
                taskStates = [...this.plannedTaskStates, ...this.activeTaskStates];
                taskUserStates = [
                    ...this.plannedTaskUserStates,
                    ExerciseSessionUserState.ACTIVE,
                    ExerciseSessionUserState.NO_SHOW,
                ];
                sortBy = [
                    SortTaskPropertyMap.CALENDAR_EVENT_START_TIME,
                    SortTaskPropertyMap.DELAYED_TIME,
                    SortTaskPropertyMap.CREATED_AT,
                ];
                sortOrder = SortOrder.ASC;
                tableColumnId = 'appointment';
                break;
            case SegmentType.ACTIVE:
                /*
                 * "Active" tasks can have 2 possible "UserStates", which are either "PLANNED" or "ACTIVE"
                 * This is a by-product of how ExerciseSessions work in the Learning and Training modules
                 * A "PLANNED" user state means that the user has not started the task yet (has not clicked on the "fulfill task" button)
                 * An "ACTIVE" user state means that the user has already clicked on the "fulfill task" button, but has not yet finished it
                 * The only case where this is possible is with MyMedax forms, by clicking on "fill form" but then not actually filling it out
                 * In all other cases the task state and user state will instantly change to "FINISHED" after clicking on "fulfill task"
                 */
                taskStates = this.activeTaskStates;
                taskUserStates = [
                    ...this.plannedTaskUserStates,
                    ExerciseSessionUserState.ACTIVE,
                    ExerciseSessionUserState.NO_SHOW,
                ];
                sortBy = [
                    SortTaskPropertyMap.DELAYED_TIME,
                    SortTaskPropertyMap.CALENDAR_EVENT_START_TIME,
                    SortTaskPropertyMap.CREATED_AT,
                ];
                sortOrder = SortOrder.ASC;
                tableColumnId = 'dueDate';

                break;
            case SegmentType.CANCELLED:
                taskStates = this.cancelTaskStates;
                taskUserStates = this.cancelTaskUserStates;
                sortBy = [SortTaskPropertyMap.LATEST_STATE_CHANGE];
                sortOrder = SortOrder.DESC;
                tableColumnId = 'dueDate';
                break;
            case SegmentType.INACTIVE:
                taskStates = this.finishedTaskStates;
                taskUserStates = this.finishedTaskUserStates;
                sortBy = [SortTaskPropertyMap.LATEST_STATE_CHANGE];
                sortOrder = SortOrder.DESC;
                tableColumnId = 'dueDate';
                break;
            case SegmentType.EXPIRED:
                // The ExerciseSessionUserState is expired (NO_SHOW), but the task itself is ExerciseSessionState.FINISHED
                taskStates = this.finishedTaskStates;
                taskUserStates = this.expiredTaskStates;
                sortBy = [SortTaskPropertyMap.LATEST_STATE_CHANGE];
                sortOrder = SortOrder.DESC;
                tableColumnId = 'dueDate';
                break;
            default:
                break;
        }
        return { taskStates, taskUserStates, sortOrder, sortBy, tableColumnId: tableColumnId };
    }

    async updateResponsible(task: TaskResource, responsible: TaskResponsibleDto): Promise<void> {
        const url = new URL(task._links.responsible.href, ApiService.url);
        try {
            await this.http
                .patch<void>(url.toString(), { username: responsible.username }, ApiService.options)
                .toPromise();
        } catch (error) {
            this.log.error('Error in updateResponsible', error);
        }
    }
}
